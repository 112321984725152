$stacked-links-link-padding: rem-calc(16 20);
$stacked-links-link-padding--large: $stacked-links-link-padding;
$stacked-links-link-active-color: color(text);
$stacked-links-link-hover-color: $stacked-links-link-active-color;
$stacked-links-link-active-font: #{rem-calc(12)} / 1.25 $font-secondary;
$stacked-links-link-font: #{rem-calc(12)} / 1.25 $font-primary;
$stacked-links-link-hover-font: $stacked-links-link-active-font;
$stacked-links-item-first-child-border-top: 1px solid color(border);
$stacked-links-link-icon-margin: 0;
$stacked-links-link-logout-background: color(primary);
$stacked-links-link-logout-font: #{rem-calc(14)} / 1.25 $font-tertiary;
$stacked-links-link-logout-padding: rem-calc(15 20);
$stacked-links-link-logout-icon-margin: 0;
$stacked-links-link-logout-icon-margin--large: 0;
$stacked-links-link-logout-margin: rem-calc(20 0 0);
$stacked-links-link-logout-hover-background: color(primary-active);
$stacked-links-link-logout-text-transform: capitalize;

@import "@lora/05-components/stacked-links";