$video-library-item-image-height: rem-calc(70);
$video-library-item-image-height--large: rem-calc(100);
$video-library-item-margin--large: rem-calc(0 0 20 0);
$video-library-item-icon-height: rem-calc(32);
$video-library-item-icon-height--large: $video-library-item-icon-height;
$video-library-item-icon-width: rem-calc(32);
$video-library-item-icon-width--large: $video-library-item-icon-width;
$video-library-item-name-color: color(primary);
$video-library-item-description-color--large: color(organism-background);
$video-library-item-name-font-size: rem-calc(14);
$video-library-item-name-font-size--large: rem-calc(18);
$video-library-playlist-title-font: 500 #{rem-calc(18)} / 1.5 $font-tertiary;
$video-library-playlist-title-text-transform: initial;
$video-library-playlist-title-text-align: center;

@import "@lora/05-components/video-library";