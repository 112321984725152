$navigation-tile-title-text-align: center;
$navigation-tile-title-text-align--large: $navigation-tile-title-text-align;
$navigation-tile-title-font-size: rem-calc(12);
$navigation-tile-title-font-size--large: $navigation-tile-title-font-size;
$navigation-tile-title-color: color(primary);
$navigation-tile-title-color--large: color(primary);
$navigation-tile-title-line-height: 1.25;
$navigation-tile-title-line-height--large: $navigation-tile-title-line-height;
$navigation-tile-title-font: #{$navigation-tile-title-font-size} / $navigation-tile-title-line-height $font-secondary;
$navigation-tile-title-font--large: #{$navigation-tile-title-font-size--large} / $navigation-tile-title-line-height--large $font-secondary;
$navigation-tile-margin--large: rem-calc(0 0 20);
$navigation-tile-margin--large: rem-calc(0 0 45);
$navigation-tile-title-margin: rem-calc(16 0 0);
$navigation-tile-title-margin--large: rem-calc(8 0 0);

@import "@lora/05-components/navigation-tile";