$video-asset-icon-width: rem-calc(32);
$video-asset-icon-width--large: $video-asset-icon-width;
$video-asset-icon-height: rem-calc(32);
$video-asset-icon-height--large: $video-asset-icon-height;
$video-asset-info-margin: rem-calc(32) 0 0;
$video-asset-info-margin--large: rem-calc(15) 0 0;
$video-asset-info-name-font: bold #{rem-calc(18)} / 1.5 $font-tertiary;
$video-asset-info-name-font--large: $video-asset-info-name-font;
$video-asset-info-name-color: color(primary);

@import "@lora/05-components/video-asset";